<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard title="Cupones" subtitle="Listado de cupones del cliente"></titleCard>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="buyer.cupones"
          class="elevation-0 order-table"
          color="secondary"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          no-data-text="No hay cupones ingresados"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td v-html="props.item.codigo"></td>
              <td v-html="props.item.nombre"></td>
              <td v-html="props.item.desde"></td>
              <td v-html="props.item.hasta"></td>
              <td v-html="`${props.item.monto}${!props.item.tipo ? '%' : ' de descuento'}`"></td>
              <td>
                <v-btn
                  v-if="(user.role.id === 1 || user.role.id === 2) && user.shop.admin"
                  class="mx-0"
                  :to="{
                    name: 'editDiscountCoupons',
                    params: { id: props.item.id }
                  }"
                  flat
                  icon
                  small
                >
                  <v-icon small>
                    edit
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="(user.role.id === 1 || user.role.id === 2) && user.shop.admin"
                  class="mx-0"
                  flat
                  icon
                  small
                  @click="deleteItemAction(props.item)"
                >
                  <v-icon small>
                    delete
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { COUPON } from '../../config'
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'BuyerSii',
  components: { titleCard },
  props: ['buyer', 'getBuyer'],
  data: () => ({
    headers: [
      { text: 'Codigo', align: 'left', sortable: false },
      { text: 'Descripcion', align: 'left', sortable: false },
      { text: 'Desde', align: 'left', sortable: false },
      { text: 'Hasta', align: 'left', sortable: false },
      { text: 'Monto', align: 'left', sortable: false },
      { text: '', align: 'left', sortable: false }
    ],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  methods: {
    async deleteItem(item) {
      try {
        await this.$http.delete(`${COUPON}/${item.id}`)
        this.getBuyer()
        this.$store.dispatch('setConfirm', { active: false })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    deleteItemAction(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar cupon?',
        description: 'Se eliminara del sistema, no se puede recuperar',
        action: () => this.deleteItem(item)
      })
    }
  }
}
</script>

<style></style>
